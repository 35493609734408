/* eslint-disable array-callback-return */
import React, {FC, useState, useEffect, Fragment, useMemo} from 'react'
import {DefaultConfig, IMenu} from 'src/_metronic/layout/components/core/_menu-config'
import {useAuth} from 'src/app/modules/auth'
import {Menu} from './Menu'
import {useQuery} from 'react-query'
import {getAllMenu} from 'src/app/modules/applications/management-menu/menu-settings/core/request'
import Swal from 'sweetalert2'
import {DataMenu} from 'src/_metronic/layout/components/core/request-menu/types/role-types'
import {ADJUSTMENT_SERVICE_MENU} from 'src/_metronic/layout/components/core/_data'
import {SidebarMenuItem} from '../../SidebarMenuItem'

// /**
//  * Fetch Data Total Pelayanan
//  * this function usage for handling fetching data
//  *
//  * @returns {Object}
//  */
// const fetchData = async () => {
//   const response = await PelayananService.totalPermohonanPbb()

//   if (response.status) {
//     const {baru, ditetapkan, ditolak, diverifikasi} = response.data[0]

//     const newData = {
//       menunggu_permohonan: baru,
//       diproses: diverifikasi,
//       ditolak,
//       selesai: ditetapkan,
//     }

//     // You can use the newly fetched data directly here
//     return newData

//     // Update your state or do other actions with the new data as needed
//   } else {
//     // Handle errors here
//     console.error('Failed to fetch data')
//   }
// }

/**
 * SideBar Menu Operator Role
 *
 * This element usage for handling custom sidebar in operator
 * role like hide and show sidebar after choosing element
 * and handling data span in menu `Daftar Permohonan` for handling request
 * lastes data pelayanan each child menu `Daftaer Permohonan`.
 *
 * Value Current Menu Layanan:
 * - PBBPP
 * - BPHTB
 * - EMPTY
 *
 * @returns {React.ReactElement}
 */
const SideBarMenuKabid: FC = React.memo(() => {
  const currentOperatorLayanan: string | null = localStorage.getItem('current-menu-layanan')
  const [menuOperator, setMenuOperator] = useState(currentOperatorLayanan ?? 'EMPTY')
  const menuLayananOperator = useMemo(() => menuOperator, [menuOperator])
  const {auth} = useAuth()
  let menu: IMenu[] = DefaultConfig

  useEffect(() => {
    const sidebarMenuElementParent = document.querySelector('[sidebar-menu-operator]')
    // Custom event listener for changes to the attribute
    const handleAttributeChange = () => {
      const sidebarMenuElement = document.querySelector('[sidebar-menu-operator]')
      if (sidebarMenuElement) {
        const attributeValue = sidebarMenuElement.getAttribute('sidebar-menu-operator')
        if (attributeValue !== menuOperator) {
          setMenuOperator(attributeValue ?? '')
        }
      }
    }

    // Initial setup
    handleAttributeChange()

    // Attach the event listener
    sidebarMenuElementParent?.addEventListener(
      'sidebar-menu-operator-change',
      handleAttributeChange
    )

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('sidebar-menu-operator-change', handleAttributeChange)
    }
  }, [menuOperator])

  let useGetMenu = () => {
    return useQuery({
      queryKey: ['Get All Menu'],
      queryFn: async () => {
        const res = await getAllMenu()
        return res.data
      },
      onError: (err) => {
        Swal.fire({
          title: 'Gagal',
          icon: 'error',
          text: String(err),
        })
      },
      refetchOnWindowFocus: false,
    })
  }

  const newMenu = useGetMenu()

  return (
    <>
      <SidebarMenuItem
        key={crypto.randomUUID()}
        to='dashboard'
        icon='element-11'
        title={`Dashboard \n ${auth?.user?.username}`}
        fontIcon='duotune'
      />
      <div sidebar-menu-operator={menuLayananOperator}>
        {newMenu?.data?.map((data: DataMenu) => {
          return (
            <Fragment key={crypto.randomUUID()}>
              <Menu
                menuOperator={menuLayananOperator}
                title={data.title}
                data={data}
                adjustment={[ADJUSTMENT_SERVICE_MENU.PBBPP, ADJUSTMENT_SERVICE_MENU.BPHTB]}
                guards={auth!.user!.role}
              />
            </Fragment>
          )
        })}
      </div>
    </>
  )
})

export {SideBarMenuKabid}
