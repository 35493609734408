import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import {formatNop} from 'src/app/helpers'
import {Information, List} from 'src/app/services/sppt/type'

export const cekPembayaranPdf = (data: List[], information: Information) => {
  const doc = new jsPDF()

  doc.setFontSize(14)
  doc.text('Cek Pembayaran PBB-P2', 105, 10, {align: 'center'})
  doc.setFontSize(12)
  doc.text(
    `${information.alamat}, ${information.no_sertifikat ?? ' '}${
      information.no_sertifikat === null ? '' : ','
    } ${information.kecamatan}`,
    105,
    18,
    {align: 'center'}
  )
  doc.text(formatNop(information?.nop), 105, 26, {align: 'center'})
  doc.setFontSize(10)

  // Define table headers and rows
  const tableHeaders = [
    'No',
    'Nama WP',
    'Tahun Pajak',
    'Jatuh Tempo',
    'Bayar',
    'Pokok PBB',
    'Denda',
    'Jumlah Tagihan',
    'Status',
  ]

  const sumTotalDendaAndJumlahTagihan = (): number => {
    let totalDenda: number = 0
    let jumlahTotal: number = 0

    data.reduceRight(
      (acc, curr) => {
        if (curr.status === 'Fasilitas Umum') {
          return acc
        } else if (curr.status === 'belum dibayar') {
          totalDenda += curr.denda
          jumlahTotal += curr.jumlah_tagihan
        } else {
          totalDenda = 0
          jumlahTotal = 0
        }
      },
      [0]
    )
    return totalDenda + jumlahTotal
  }

  const jumlahTagihan = () => {
    data?.map((item) => {
      if (item.status === 'Fasilitas Umum') {
        return 0
      }
    })
    return data.reduce(
      (prev, next) => {
        if (next.status === 'belum dibayar') {
          return (typeof prev === 'number' ? prev : 0) + (next.jumlah_tagihan ?? 0)
        }
        return prev
      },
      [0]
    ) as number
  }
  const jumlahDenda = () => {
    data?.map((item) => {
      if (item.status === 'Fasilitas Umum') {
        return 0
      }
    })

    return data.reduce(
      (prev, next) => {
        if (next.status === 'belum dibayar') {
          return (typeof prev === 'number' ? prev : 0) + (next.denda ?? 0)
        }
        return prev
      },
      [0]
    ) as number
  }

  const tableRows = data.map((row, index) => [
    index + 1,
    row?.nama_wp,
    row?.tahun_pajak,
    moment(row?.tanggal_jatuh_tempo).format('DD-MM-YYYY') !== 'Invalid date'
      ? moment(row?.tanggal_jatuh_tempo).format('DD-MM-YYYY')
      : '-',
    moment(row?.tanggal_dibayar).format('DD-MM-YYYY') !== 'Invalid date'
      ? moment(row?.tanggal_dibayar).format('DD-MM-YYYY')
      : '-',
    row?.jumlah_tagihan?.toLocaleString(),
    row?.denda?.toLocaleString(),
    (row?.jumlah_tagihan + row?.denda).toLocaleString('id-ID'),
    {
      content: row?.status,
      styles: {
        textColor: row?.status === 'belum dibayar' ? 'red' : 'blue',
      },
    },
  ])

  const drawTotal = [
    {
      content: `Jumlah Tunggakan PBB s/d tanggal bayar ${moment(new Date()).format('DD-MM-YYYY')}`,
      colSpan: 5,
      styles: {halign: 'left', textColor: 'red', fillColor: '#ecf0f1', fontStyle: 'bold'},
    },
    {
      content: jumlahTagihan().toLocaleString('id-ID'),
      styles: {halign: 'center', textColor: 'red', fillColor: '#ecf0f1', fontStyle: 'bold'},
    },
    {
      content: jumlahDenda().toLocaleString('id-ID'),
      styles: {halign: 'center', textColor: 'red', fillColor: '#ecf0f1', fontStyle: 'bold'},
    },
    {
      content: (jumlahTagihan() + jumlahDenda()).toLocaleString('id-ID'),
      styles: {halign: 'center', textColor: 'red', fillColor: '#ecf0f1', fontStyle: 'bold'},
    },
    {content: '', styles: {halign: 'center', textColor: 'red', fillColor: '#ecf0f1'}},
  ]

  const drawTableBody = [...tableRows, drawTotal]

  autoTable(doc, {
    head: [tableHeaders],
    body: drawTableBody,
    startY: 35,
    styles: {fontSize: 10, halign: 'center', valign: 'middle'},
    columnStyles: {8: {halign: 'center'}}, // Right-align the "Status" column
    bodyStyles: {fontSize: 8},
    headStyles: {fontSize: 8},
    theme: 'striped', // Add a striped theme
  })

  doc.save('Cek Pembayaran PBB-P2.pdf')
}
