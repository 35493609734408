import {ResponseServerWithMessage} from 'src/app/types/api'
import {
  UserData,
  UserNotaris,
  UserPayload,
  UserResetPasswordAdminPayload,
  UserResetPasswordPayload,
} from './type'
import axios from 'axios'
import {objectToFormData} from 'src/app/helpers/index'

interface IUserServiceInstance {
  list(page?: string, queryRequest?: object): Promise<ResponseServerWithMessage<UserData[]>>
  delete(id: string): Promise<ResponseServerWithMessage<string>>
  detail(id: string): Promise<ResponseServerWithMessage<UserData[]>>
  store(payload: UserPayload): Promise<ResponseServerWithMessage<null>>
  update(userId: string, payload: UserPayload): Promise<ResponseServerWithMessage<null>>
  resetPassword(
    id: string,
    paylaod: UserResetPasswordPayload
  ): Promise<ResponseServerWithMessage<null>>
  searchUserByName(name: string): Promise<ResponseServerWithMessage<UserData[]>>
}

class IUserServiceClass {
  static KABID = 'kabid'
  static KASUBBID = 'kasubbid'
  static SUPER_ADMIN = 'superadmin'
  static ADMIN = 'admin'
  static ADMIN_BPHTB = 'admin_bphtb'
  static ADMIN_PBB = 'admin_pbb'
  static OPERATOR = 'operator'
  static KELURAHAN = 'kelurahan'
  static KECAMATAN = 'kecamatan'
  static NOTARIS = 'notaris'
  static ADMIN_KOLEKTOR = 'admin_kolektor'
  static KOLEKTOR = 'kolektor'

  static PENATAAN_PBB = 'Pendataan PBB'

  static PETUGAS_LAPANGAN = 'petugas lapangan'

  static STATIC_KABID = 'ANDI ANTORIO GINTING,SH'
  static STATIC_NIP_KABID = '198203302008011002'

  // public static list = (path?: string, page?: string, queryRequest?: object) => {
  //   let endpoint: string = 'user/data'

  //   if (path) endpoint += '/' + path

  //   return axios
  //     .get(endpoint, {
  //       params: {
  //         page,
  //         ...queryRequest,
  //       },
  //     })
  //     .then((res) => res.data)
  // }
  public static list = (page?: string) => {
    let endpoint: string = 'user/data'

    return axios
      .get(endpoint, {
        params: {
          page,
        },
      })
      .then((res) => res.data)
  }

  public static delete = (id: string) => {
    const endpoint: string = 'user/delete/' + id

    return axios.delete(endpoint).then((res) => res.data)
  }

  public static detail = (id: string) => {
    const endpoint: string = 'user/get/' + id

    return axios.get(endpoint).then((res) => res.data)
  }

  public static store = (payload: UserPayload) => {
    const formData = objectToFormData(payload)
    const endpoint: string = 'user/store'

    return axios.post(endpoint, formData).then((res) => res.data)
  }

  public static update = (userId: string, payload: UserPayload) => {
    const endpoint: string = 'user/update/' + userId
    const formData = objectToFormData(payload)
    formData.append('_method', 'put')

    return axios.post(endpoint, formData).then((res) => res.data)
  }

  /**
   * listNotaris.
   * This method usage for get all notaris user from endpoint `notaris/data` from remote
   * data source.
   * @returns {Promise<ResponseServerWithMessage<UserNotaris[]>>}
   */
  public static listNotaris = (): Promise<ResponseServerWithMessage<UserNotaris[]>> => {
    const endpoint: string = 'notaris/data'
    return axios.get(endpoint).then((res) => res.data)
  }

  /**
   * verificationNotaris
   * this method for handling notaris verifikasi
   * @param {string} id
   * @returns {Promise<ResponseServerWithMessage<string>>}
   */
  public static verificationNotaris = (id: string): Promise<ResponseServerWithMessage<string>> => {
    const endpoint: string = 'notaris/verifikasi/' + id
    return axios.put(endpoint).then((res) => res.data)
  }

  public static resetPassword = (id: string, payload: UserResetPasswordPayload) => {
    const endpoint: string = 'user/update/password/' + id

    return axios.put(endpoint, payload).then((res) => res.data)
  }

  public static resetPasswordUser = (id: string, payload: UserResetPasswordAdminPayload) => {
    const endpoint: string = 'user/update/password/' + id

    return axios.put(endpoint, payload).then((res) => res.data)
  }

  public static searchUserByName = (name: string) => {
    const endpoint: string = 'user/search'
    return axios.get(endpoint, {params: {name}}).then((res) => res.data)
  }
}

const IUserService: IUserServiceInstance & typeof IUserServiceClass = IUserServiceClass

export default IUserService
